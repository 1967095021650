import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.select2Mount();

    document.addEventListener('turbolinks:before-cache', this.handleCache);
  }

  disconnect() {
    document.removeEventListener('turbolinks:before-cache', this.handleCache);
  }

  handleCache = () => {
    this.saveState();
    this.select2Unmount();
  }

  select2Mount() {
    this.select.select2({
      theme: 'bootstrap'
    });
  }

  select2Unmount() {
    this.select.select2('destroy');
  }

  saveState() {
    const value = this.select.val();

    if (!value) { return; }
      // Store selected in HTML before caching so select2 will re-init properly
    this.select.find(`options[value="${value}"]`).attr('selected', 'selected');
  }

  get select() {
    return $(this.targets.find('select'));
  }
}
